<template>
  <div class="tariff-plan-container">
    <Loader v-if="!ordersLoaded && !ordersLoadedHistory && !ordersLoadedSubtags"></Loader>
    <TariffPlanHeader
        v-if="ordersLoaded && ordersLoadedHistory && ordersLoadedSubtags"
    ></TariffPlanHeader>
  </div>
</template>

<script>
// import TariffPlanOff from "./TariffPlanOff.vue";
// import TariffPlanOn from "./TariffPlanOn.vue";
import TariffPlanHeader from './TariffPlanHeader.vue'
import TariffHistoryPayments from './HistoryPayments.vue'
import TariffPlanTablePayments from './TariffPlanTablePayments.vue'
import Loader from "@/components/common/Loader.vue";

export default {
  props: ['isHistoryPay', 'isTariffPlan'],
  async beforeMount() {
      await this.$store.dispatch('setSubTags')
      await this.$store.dispatch('setOrders')
      await this.$store.dispatch('setOrdersHistory')
  },
  components: {
    Loader,
    TariffPlanHeader,
    TariffPlanTablePayments,
    TariffHistoryPayments
  },
  computed: {
    ordersLoaded () {
      return this.$store.getters.getOrdersLoaded;
    },
    ordersLoadedHistory () {
      return this.$store.getters.getOrdersLoadedHistory;
    },
    ordersLoadedSubtags () {
      return this.$store.getters.getSubTagsLoaded;
    },
  },
  methods: {
    toggleHistory() {
      this.isHistory = !this.isHistory
    }
  }
}
</script>

<style lang="scss">
.tariff-plan-container {
  width: 100%;
  height: 100%;

  padding: 50px 33px 33px 33px;
  background-color: white;
}

@media(max-width: 992px) {
}

@media(max-width: 768px) {
  .tariff-plan-container{
    padding: 20px;
  }
}

@media(max-width: 576px) {
}

</style>
