<template>
  <div class="history_payments">
    <div class="history_payments_title">История платежей</div>
<!--    <div class="history_payments_header">-->
<!--      <div>-->
<!--        Карта-->
<!--      </div>-->
<!--      <div>-->
<!--        Дата-->
<!--      </div>-->
<!--      <div>-->
<!--        Сумма-->
<!--      </div>-->
<!--    </div>-->
      <div class="history_payments_table" v-for="row of ordersHistory">
        <div class="history_payments_row">
          <div class="row_date">{{row.created_at}}</div>
          <div class="row_month">{{row.rate}} {{row.rate === 1 ? 'месяц' : row.rate === 2 || row.rate === 3 || row.rate === 4 ? 'месяца' : 'месяцев' }}</div>
          <div></div>
          <div>
            <div class="payed">Оплачено</div>
          </div>
          <div>{{row.rate * 150}}</div>
        </div>
      </div>
  </div>

</template>

<script>

export default {
  props: [],
  components: {},
  data () {
    return {}
  },
  computed: {
    ordersHistory () {
      return this.$store.getters.getOrdersHistory;
    }
  },
  methods: {}
}
</script>

<style lang="scss">

.history_payments{
  width: 100%;
  &_title{
    width: 100%;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

  }
  &_table{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.payed{
  width: fit-content;
  padding: 7px 16px;
  background-color: #F6F6F6;
  border-radius: 3px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

}

.row_date{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.row_month{
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

@media(max-width: 992px) {
}

@media(max-width: 768px) {
}

@media(max-width: 576px) {
}

</style>
