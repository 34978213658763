<template>
  <div class="tariff__plan__header">
    <div class="tariff__plan__header__main__label">
      <div class="tariff__plan__header__main__label__static">
        Ваш тарифный план
      </div>
<!--      TODO UNCOMMENT-->
<!--      <Loader class="cell_wrapper" v-if="!this.$store.getters.getOrdersLoaded" />-->
<!--      <div v-if="this.$store.getters.getOrdersLoaded">-->

<!--      <div>-->
<!--        <div v-if="orders.length == 0" class="tariff__plan__header__main__label__status"> Бесплатный</div>-->
<!--        <div v-if="orders.length > 0" class="tariff__plan__header__main__label__status"> {{orders[0].rate}}-->
<!--          {{ monthText(orders[0].rate) }}-->
<!--        </div>-->
<!--      </div>-->

    </div>

    <!--      <div v-if="this.$store.getters.getOrdersLoaded"  class="orders_container" v-for="order in orders">-->
<!--    <div class="orders_container">-->
<!--      <div v-for="order in orders" class="order_tag">-->
<!--        {{order.subtags[0].name}}-->
<!--      </div>-->
<!--    </div>-->

    <div v-if="orders.length == 0" class="tariff__plan__header__label__data">
      У вас нет подключенных подписок
    </div>
<!--    <div v-if="orders.length > 0" class="tariff__plan__header__label__desc">-->
<!--      Тариф оплачен до: {{orders[0].expired_date}}-->
<!--    </div>-->
<!--    <div @click="autoTariffChange" class="tariff__plan__header__label__desc auto">-->
<!--      Автопродление включено-->
<!--    </div>-->
<!--    <div @click="autoTariffChange" class="tariff__plan__header__label__desc auto">-->
<!--      Автопродление выключено-->
<!--    </div>-->
    <div v-if="ordersHistory.length > 0 && !isHistory" @click="toggleHistory" class="tariff__plan__header__label__link">
      История платежей
      <img src="@/assets/image/arrow-red.svg" />
    </div>
    <div v-if="ordersHistory.length > 0 && isHistory" @click="toggleHistory" class="tariff__plan__header__label__link">
      Покупка тарифа
      <img src="@/assets/image/arrow-red.svg" />
    </div>
<!--    <div v-if="ordersHistory.length > 0 && !isHistory"  class="tariff__plan__header__label__table__header">-->
<!--      <b>При понижении тарифного плана</b> оплаченный период действия текущей подписки-->
<!--      не пересчитывается.-->
<!--    </div>-->
    <!-- <div>Ваш тарифный план: 1 месяц</div>
    <div>Тариф оплачен до: 09.05.21</div>
    <div>Автопродление включено</div>

    <div @click="toHistoryPayments">История платежей</div>

    <div>
      При понижении тарифного плана оплаченный период действия текущей подписки
      не пересчитывается.
    </div> -->
      <TariffPlanTablePayments v-if="!isHistory"></TariffPlanTablePayments>
      <TariffHistoryPayments v-if="isHistory"></TariffHistoryPayments>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader.vue'
import TariffHistoryPayments from './HistoryPayments.vue'
import TariffPlanTablePayments from './TariffPlanTablePayments.vue'
export default {
  components: {
    Loader,
    TariffHistoryPayments,
    TariffPlanTablePayments
  },

  data () {
    return {
      isHistory: false,
    }
  },
  computed: {
    orders () {
      return this.$store.getters.getOrders ? this.$store.getters.getOrders : [];
    },
    ordersHistory () {
      return this.$store.getters.getOrdersHistory;
    }
  },
  methods: {
    toggleHistory () {
      console.log('isHistory')
      this.isHistory = !this.isHistory
    },
    autoTariffChange() {
      this.$notification.open({
        message: 'Необходимо добавить функциональность'
      })
    },
    monthText(month) {
      switch (month) {
        case 1:
          return 'месяц'
        case 2:
          return 'месяца'
        case 3:
          return 'месяца'
        case 4:
          return 'месяца'
        default:
          return 'месяцев'
      }
    }
  }
}
</script>

<style lang="scss">

.orders_container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 80%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.order_tag{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 8px;
  color: white;
  background-color: #ed2e23;
}

.tariff__plan__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__main__label {
    display: flex;
    margin-bottom: 38px;
    &__static {
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    &__status {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 5px;
    }
  }
  &__label {
    &__data {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;
      &__insert {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    &__desc {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.58);
      margin-bottom: 12px;
      &.auto{
        cursor: pointer;
      }
    }
    &__link {
      color: rgba(177, 32, 33, 1);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 38px;
      cursor: pointer;

      img {
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
      }
    }
    &__table__header {
      margin-bottom: 17px;
      &__bold {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
@media(max-width: 992px) {
  .tariff__plan__header {
    &__main__label {
      &__static {
      }
      &__status {
      }
    }
    &__label {
      &__data {
        &__insert {
        }
      }
      &__desc {
      }
      &__link {

        img {
        }
      }
      &__table__header {
        &__bold {
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .tariff__plan__header {
    &__main__label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__static {
        text-align: center;
        line-height: 40px;
      }
      &__status {
        text-align: center;
      }
    }
    &__label {
      &__data {
        &__insert {
        }
      }
      &__desc {
      }
      &__link {

        img {
        }
      }
      &__table__header {
        &__bold {
        }
      }
    }
  }
}

@media(max-width: 576px) {
  .tariff__plan__header {
    &__main__label {
      margin-bottom: 18px;
      &__static {
        font-size: 22px;
        line-height: 30px;
      }
      &__status {
        font-size: 22px;
      }
    }
    &__label {
      &__data {
        &__insert {
        }
      }
      &__desc {
      }
      &__link {
        margin-bottom: 18px;
        img {
        }
      }
      &__table__header {
        &__bold {
        }
      }
    }
  }
}

</style>
