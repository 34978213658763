<template>
  <div class="tariff-plan-table">
    <div class="tariff-row-table-container">
      <div
          class="tariff-row-table"
          v-for="tag in computedTags"
      >
        <TariffPlanCell @select="selectTag" @delete="deleteTag" :tag="tag" ></TariffPlanCell>
      </div>
      <div class="tariff-table-selected-tags" v-if="selectedTags.length > 0">
        <div class="tariff-table-selected-tag" v-for="tag in selectedTags" @click="deleteTag(tag.id)">
          {{tag.name}}
        </div>
      </div>
      <div class="tariff-table-pay-container" v-if="selectedTags.length > 0">
        <a-select class="tariff-month" @change="updateMonth" placeholder="Выберите срок">
          <a-select-option value="1">
            1 месяц
          </a-select-option>
          <a-select-option value="3">
            3 месяца
          </a-select-option>
          <a-select-option value="6">
            6 месяцев
          </a-select-option>
          <a-select-option value="12">
            12 месяцев
          </a-select-option>
        </a-select>
        <Button @click="openModal" :disabled="month == 0" class="tariff-table-button">Оплатить</Button>
        <div>
          Общая сумма для оплаты: {{sum}} руб.
          <!--        <div class="tariff-row-table-price">Сумма: {{ sum }} рублей</div>-->
        </div>
      </div>
    </div>
    <a-modal :footer="null" :visible="isModalPayment" @cancel="closeModal">
      <div class="tariff-table-modal-content">
        Привет! Здесь скоро появится ссылка для оплаты выбранного вами
        тариффного плана, а пока можете пользоваться сервисом бесплатно))
      </div>
      <Button @click="pay" class="tariff-table-button">Оплатить</Button>
    </a-modal>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'
import TariffPlanCell from './TariffPlanCell.vue'
import Loader from "@/components/common/Loader.vue";
import {getBearer} from "@/helpers/authHelper";

export default {
  components: {
    Button,
    TariffPlanCell,
    Loader
  },
  data() {
    return {
      // sum: 0,
      selectedTagsId: [],
      orders: [],
      tags: [],
      month: 0,
      isModalPayment: false,
    }
  },
  computed: {
    sum() {
      return this.selectedTagsId.length * 150 * this.month
    },
    computedTags () {
     return this.tags
    },
    selectedTags () {
      return this.tags.filter((tag) => {
       return this.selectedTagsId.includes(tag.id)
      })
    }
  },
  mounted () {
    this.orders = this.$store.getters.getOrders ? this.$store.getters.getOrders : [];
    const tagsArr = this.$store.getters.getSubTags
    if (this.orders.length == 0) {
      this.tags = tagsArr.map((tag) => ({...tag, selected: false}))
    } else {
      this.tags = tagsArr.map((tag) => {
        if (this.orders.find((sub) => sub.subtags.includes(tag.id))) {
          return  {...tag, selected: true}
        } else {
          return {...tag, selected: false}
        }
      })
    }
  },
  methods: {
    selectTag(tagId) {
      if (!this.selectedTagsId.includes(tagId)) {
        this.selectedTagsId.push(tagId)
      }
      this.tags.forEach((t) => {
        if (t.id == tagId) {
          t.selected = true
        }
      })
    },
    deleteTag(tagId) {
      this.selectedTagsId = this.selectedTagsId.filter((item) => item !== tagId)
      this.tags.forEach((t) => {
        if (t.id === tagId) {
          t.selected = false
        }
      })
    },
    openModal() {
      this.isModalPayment = true
    },
    closeModal () {
      this.isModalPayment = false
    },
    updateMonth(value) {
      this.month = Number(value);
    },
    monthText(month) {
      switch (month) {
        case 1:
          return 'месяц'
        case 2:
          return 'месяца'
        case 3:
          return 'месяца'
        case 4:
          return 'месяца'
        default:
          return 'месяцев'
      }
    },
    async pay() {
      const url = process.env.VUE_APP_SERVER_URL + '/orders'
      await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        },
        body: JSON.stringify({subtagId: this.selectedTagsId, rate: this.month})
      })
          .then((response) => response.json())
          .then((res) => {
            if (res.data) {
              this.$notification.open({
                message: 'Вы купили подписку!',
                description: res.message
              })
              this.$store.dispatch('setOrders')
              this.$store.dispatch('setOrdersHistory')
              this.isModalPayment = false
              this.selectedTagsId = []
              this.month = 0
            }

          })
          .catch((e) => {
            console.log(e, 'e')
            this.$notification.open({
              message: 'Ошибка покупки тега',
            })
            this.isModalPayment = false
          })

    }
  }
}


</script>
<style>
.tariff-row-table-container {
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
}

.tariff-plan-table{
  width: 100%;
}

.tariff-table-modal-content{
  margin-top: 20px;
}

.tariff-table-button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 126px;
  border-radius: 3px;
  color: white;
  background-color: #b12021;
}

.tariff-row-table {
  width: 100%;
  display: flex;
  padding: 14px;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #B7B3B3;
  margin-bottom: 20px;
}

.tariff-month{
  flex-shrink: 2;
  width: 100%;
  max-width: 300px;
}

.tariff-table-selected-tags{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.tariff-table-selected-tag{
  padding: 10px;
  color: white;
  background-color: #b12021;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.tariff-table-pay-container{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

@media(max-width: 768px) {
  .tariff-row-table{
    flex-direction: column;
  }
}

@media(max-width: 576px) {
}

</style>
