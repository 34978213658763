<template>
  <div class="tariff-cell">
    <div class="tariff-cell-tag" :style="{backgroundColor: tag.color}">
      {{ tag.name }}
    </div>
    <div v-if="!order" class="tariff-cell-spent">
      <div class="tariff-row-table-button-container">
        <Button v-if="!selected" @click="selectAction" class="tariff-row-table-button">+</Button>
        <Button v-if="selected" @click="deleteAction" class="tariff-row-table-button">-</Button>
      </div>
    </div>
    <div v-if="order" class="tariff-cell-order">
       Подписка оформлена на <span class="tariff-order-value">{{order.rate}} {{ monthText(order.rate) }}</span>, до <span class="tariff-order-value"> {{order.expired_date}}</span>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'

export default {
  props: ["tag"],
  name: "TariffPlanCell",
  components: {
    Button,
  },
  computed: {
    selected() {
      return this.tag.selected
    },
    order () {
      const orders = this.$store.getters.getOrders ? this.$store.getters.getOrders : [];
      if (orders.length == 0) {
        return ''
      } else {
        const orderTag = orders.find((order) => {
          return order.subtags.find((sub) => sub.id == this.tag.id)
        })
        return orderTag
      }
    }
  },
  methods: {
    getWorkTagsValue () {
      let arr = []
      this.$store.getters.getTags.map((object) => {
        const subtags = object.subtags
        for (const subtag of subtags) {
          arr.push(subtag)
        }
      })
      return arr
    },

    async selectAction () {
      this.$emit('select', this.tag.id)
    },

    async deleteAction () {
        this.$emit('delete', this.tag.id)
    },

    monthText(month) {
      switch (month) {
        case 1:
          return 'месяц'
        case 2:
          return 'месяца'
        case 3:
          return 'месяца'
        case 4:
          return 'месяца'
        default:
          return 'месяцев'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tariff-cell{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.tariff-cell-tag{
  color: white;
  border-radius: 8px;
  padding: 10px;
  min-width: fit-content;
}

.tariff-cell-spent{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.tariff-cell-order{
  max-width: 50%;
}

.tariff-row-table-button-container {
  flex: 5;
  display: flex;
  justify-content: flex-end;
}
.tariff-row-table-button {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: 86px;
  border-radius: 3px;
  color: white;
  background-color:  rgb(194, 100, 100);
}

.tariff-order-value{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

@media(max-width: 800px) {
  .tariff-cell{
    flex-direction: column;
  }
  .tariff-cell-order{
    max-width: 100%;
  }

}

</style>
